import React, { useState, useContext, useEffect } from 'react';
import DateContext from '../../context/dateContext';
import { range } from '../../services/calculateService';
import moment from 'moment';
const formaty = 'YYYY-MM-DD';
const formatw = 'YYYY-W';

function getWeek(date,i) {
  let start = moment(date).isoWeekday(1).format('D MMM');
  let end = moment(date).isoWeekday(7).format('D MMM');
  let weeknr = moment(date).isoWeekday(1).format('W');
  let year = moment(date).isoWeekday(1).format('YYYY');
  if (i>50 && weeknr==="1") year = `${parseInt(year)+1}`;
  return {id: `${year}-${weeknr}`, nr: weeknr, text: `Week ${weeknr} (${start.toLocaleLowerCase()} - ${end.toLocaleLowerCase()})`};
}

function getWeeks(year) {
  let arr = [];
  let date = moment(`${year}-01-01`);
  let i = 0;
  
  while (moment(date) <= moment(`${year}-12-31`,formaty) ) {
    arr.push(getWeek(date,i));
    date = moment(date).add(7, 'day');
    i++;
  }

  return arr;
}

const WeekPicker = () => {
    const dateContext = useContext(DateContext);

    let startyear = moment().year();
    let years = range(2017,startyear+1);
    let startweek = parseInt(moment().format('W'));

    const [year, setYear] = useState(startyear);
    const [week, setWeek] = useState(`${startyear}-${startweek}`);
    const [weeks, setWeeks] = useState(getWeeks(startyear));

    useEffect(() => {      
      let start = new Date(moment(week, "YYYY-W").isoWeekday(1).format(formaty));
      let end = new Date(moment(week, "YYYY-W").isoWeekday(7).format(formaty));
      dateContext.changeDaterange(start,end);
    }, [week]);

    useEffect(() => {  
      setWeeks(getWeeks(year));
    }, [year]);

    useEffect(() => {
      let oldweeknr = week.split('-')[1];
      if (parseInt(oldweeknr)< 1 || parseInt(oldweeknr) > 52) oldweeknr = "1";
      let newweek = `${year}-${oldweeknr}`;
      setWeek(newweek);
    }, [weeks]);

    function changeYear() {
      const y = document.getElementById("idYear");
      if (y) setYear(y.value);
    }

    function changeWeek() {
      const y = document.getElementById("idWeek");
      if (y) setWeek(y.value);
    }

    function arrowRight() {
      let [y,w] = week.split('-');
      let nw = moment(week,formatw).isoWeekday(1).add(7, 'day').format(formatw);
      
      setWeek(nw);
      if (nw.split('-')[1]==="1") setYear(parseInt(y)+1);
    }

    function arrowLeft() {
      let [y,w] = week.split('-');
      let nw = moment(week,formatw).isoWeekday(1).subtract(7, 'day').format(formatw);
      
      setWeek(nw);
      if (w==="1") setYear(parseInt(y)-1);
    }

    return (
      <div className="rdrMonthAndYearPickers mw-100p display-ib">
        <button type="button" className="rdrNextPrevButton rdrPprevButton display-ib" onClick={arrowLeft}><i></i></button>
        <div className="rdrMonthPicker display-ib">
          <select id="idWeek" value={week} onChange={changeWeek}>
            {weeks.map( w => <option key={w.id} value={w.id}>{w.text}</option> )}
          </select>
        </div>
        <div className="rdrYearPicker display-ib">
          <select id="idYear" value={year} onChange={changeYear}>
            {years.map( y => <option key={y} value={y}>{y}</option> )}
          </select>
        </div>
        <button type="button" className="rdrNextPrevButton rdrNextButton display-ib" onClick={arrowRight}><i></i></button>
      </div>
    );
}

export default WeekPicker;
